export default class AdministrativeFilesIndex {

    constructor() {
        this.validation = null;
        this.validation_password = null;
        this.bindEvents();
        this.init();
        //window.application.setOnDataChangeListener(this);
    }

    init() {
      $.validator.addMethod("phone_number_fr", function(value, element) {
          let val = value.replace(/ /g, '');
          return val.match(/[0-9]/g) && value.replace(/ /g, '').length === 10 && (value.charAt(0) === "0");
      }, I18n.t('common.js_validation.phone_number_fr'));
  
      $.validator.addMethod("password_validation", function(value, element) {
          return value.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,70}$/g) && value.length >= 8;
      }, I18n.t('common.js_validation.password_validation'));
  
      this.validation_personal_information();
      this.validation_change_password();
  
      // Trigger validation on all form controls at page load
      let formControls = $(".form-control");
      if (formControls.length > 0) {
          setTimeout(() => {
              formControls.each(function() {
                  $(this).valid();
              });
          }, 200);
      }
  
      let hash = location.hash.replace(/^#/, '');
      if (hash) {
          $('.files-nav a[href="#' + hash + '"]').tab('show');
      }
  
      /*$('.files-nav a').on('shown.bs.tab', function (e) {
          window.location.hash = e.target.hash;
      })*/
    }

    bindEvents() {
        let self = this;
        $("#customer_profile_image").change(function (){
            self.handleFiles(this.files);
        });

        $(document).ready(function (){
            self.validation.form();
        })

        $("#customer_instructor_description").keyup(function (){
            let length = $(this).val().length
            let label = $(this).next("label")
            if(length >= 1200){
                label.addClass("text-danger")
            }else{
                label.removeClass("text-danger")
            }
            label.text(I18n.t("monitors.administrative_files.personal_info.description", {number: length}))
        });

        $('.upload_file').click(this.openUpload);

        $("input[id^=id_file_]").change(this.handleDocs);

        $(".js-ephoto").click(this.sendEphoto);

        $('#customer_birth_date').change(function (){
            if(moment($(this).val(), "DD/MM/YYYY").isAfter(moment().subtract(18, 'years'))){
                $('.js-legal_responsible').removeClass('d-none');
            }else{
                $('.js-legal_responsible').addClass('d-none');
            }
        })

        $('#customer_address_postal_code').select2({
            minimumInputLength: 1,
            ajax: {
                contentType: "application/json",
                delay: 250,
                url: $('#customer_address_postal_code').data('url'),
                dataType: 'json',
                transport: function (params, success, failure) {
                    params['contentType'] = "application/json";
                    let $request = $.ajax(params);

                    $request.then(success);
                    $request.fail(failure);

                    return $request;
                }
            },
            theme: 'bootstrap'
        });

        $('label[for=customer_address_postal_code]').click(() => {
            $('#customer_address_postal_code').select2('open');
        })

        $(document).on('select2:open', () => {
            document.querySelector('.select2-search__field').focus();
        });
    }

    onDataChanged(data) {
    }

    onDestroy() {
    }

    handleFiles(files) {
        let file = files[0]
        if(this.valid_file(file)){
            this.previewFile(file);
        }
    }

    previewFile(file) {
        let reader = new FileReader();

        reader.onload = function(e) {
            $('#profile_image_preview').attr('src', e.target.result);
        }
        //this.file = file;
        reader.readAsDataURL(file); // convert to base64 string
    }

    validation_personal_information() {
        let validation_options = {
            rules: {
                "customer[phone]":{
                    required: true,
                    phone_number_fr: true
                },
                "customer[last_name]":{
                    required: true
                },
                "customer[first_name]":{
                    required: true
                },
                "customer[birth_date]":{
                    required: true
                },
                "customer[birth_place]":{
                    required: true
                },
                "customer[email]":{
                    required: true,
                    email: true
                },
                "customer[address_line1]":{
                    required: true
                },
                "customer[address_city]":{
                    required: true
                },
                "customer[responsible_email]":{
                    required: function(){
                        return moment($('#customer_birth_date').val(), "DD/MM/YYYY").isAfter(moment().subtract(18, 'years'))
                    },
                    email: true
                },
                "customer[address_postal_code]":{
                    required: true,
                    remote: {
                        url: $('#customer_address_postal_code').data('urlvalidate'),
                        dataType: 'json'
                    },
                },
            },
            messages:{
                "customer[address_postal_code]": {
                    remote: "Code postal invalide"
                }
            },
            errorClass: 'is-invalid',
            validClass: '',
            errorElement : 'div',
            errorLabelContainer: '.invalid-feedback',
            ignore: [],
            focusInvalid: false,
            invalidHandler: function(form, validator) {

                if (!validator.numberOfInvalids())
                    return;


                $('html, body').animate({
                    scrollTop: $(validator.errorList[0].element).parent().offset().top - 100
                }, 1000);

            },
            errorPlacement: (error, element) => {
                $(element).parent().append(error);
            }
        }

        if(I18n.locale !== 'en'){
            validation_options.messages = require("jquery-validation/dist/localization/messages_"+I18n.locale);
        }

        this.validation = $("#files_form").validate(validation_options);
    }

    validation_change_password() {

        let validation_options = {
            rules: {
                "customer[current_password]":{
                    required: true
                },
                "customer[password]":{
                    required: true,
                    password_validation: true
                },
                "customer[password_confirmation]":{
                    required: true,
                    equalTo: "#customer_password"
                }
            },
            errorClass: 'is-invalid',
            errorElement : 'div',
            errorLabelContainer: '.invalid-feedback',
            ignore: [],
            focusInvalid: false,
            invalidHandler: function(form, validator) {

                if (!validator.numberOfInvalids())
                    return;


                $('html, body').animate({
                    scrollTop: $(validator.errorList[0].element).parent().offset().top - 100
                }, 1000);

            }
        }

        if(I18n.locale !== 'en'){
            validation_options.messages = require("jquery-validation/dist/localization/messages_"+I18n.locale);
        }

        this.validation_password = $("#customer_password_form").validate(validation_options);
    }

    openUpload(e) {
        let $el = $(e.currentTarget);
        if(!$el.hasClass('uploaded') && !$el.hasClass('ephoto')){
            $('#'+$el.data('input-id')).click();
        }
    }


    handleDocs(e) {
        let self = application.current_handler;
        let el = e.currentTarget;

        if(el.files[0] !== undefined){
            let id = $(el).data('id');
            let $doc = $('#document_'+id);
            $doc.find('.file-input').addClass('d-none');
            $doc.find('.spinner-border').removeClass('d-none');

            let formData = new FormData();
            formData.append("customer_document[document]", el.files[0]);
            let ajaxData = {
                url: '/administrative_files/'+$(el).data("id")+'/upload_file',
                data : formData,
                method: "POST",
                async: true,
                cache: false,
                contentType: false,
                processData: false,
                timeout: 60000
            };

            $.ajax(ajaxData)
                .done(function(data) {
                    let $doc = $('#document_'+data['element_id'])
                    $doc.removeClass('file_required').addClass('uploading');
                    $doc.find('.file-input').removeClass('d-none');
                    $doc.find('.spinner-border').addClass('d-none');
                    self.updateBadges();
                })
                .fail(function(err) {
                    let data = err.responseJSON;
                    let $doc = $('#document_'+data['element_id']);
                    $doc.addClass('file_required').removeClass('uploading');
                    $('#error_message_'+data['element_id']).text(data['error_message']);
                    $doc.find('.file-input').removeClass('d-none');
                    $doc.find('.spinner-border').addClass('d-none');
                });
        }


    }

    updateBadges() {
        let $layout_badge = $('#missing-data-layout');
        let layout_number = parseInt($layout_badge.data('number')) - 1;
        $layout_badge.text(layout_number);

        let $file_badge = $('#files-badge');
        let file_number = parseInt($file_badge.data('number')) - 1;
        $file_badge.text(file_number);
    }

    sendEphoto(e) {
        e.preventDefault();
        let el = e.currentTarget;
        let val = $("#"+$(el).data("input-id")).val();

        if(val.indexOf(' ') === -1 && val.length === 22){
            let ajaxData = {
                url: '/administrative_files/'+$(el).data("id")+'/upload_ephoto',
                data : {customer_document:{extras: {ephoto: val}}},
                method: "POST"
            };

            $.ajax(ajaxData)
                .done(function(data) {
                    $('#document_'+data['element_id']).removeClass('file_required').addClass('uploading');
                    //$('#id_ephoto_file_'+data['element_id']).prop("readonly", true);
                })
                .fail(function(err) {
                    let data = err.responseJSON;
                    $('#document_'+data['element_id']).addClass('file_required').removeClass('uploading');
                    $('#error_message_'+data['element_id']).text(data['error_message']);
                });
        }else{
            $('#document_'+$(el).data("id")).addClass('file_required').removeClass('uploading');
            $('#error_message_'+$(el).data("id")).text(I18n.t('students.administrative_files.file_item.ephoto_error'));
        }
    }

    valid_file(file) {
        if(!['image/jpg','image/jpeg','image/png'].includes(file.type)){
            $("#customer_profile_image").val('');
            return false;
        }else{
            return true;
        }
    }
}