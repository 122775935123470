export default class StudentsTutosIndex {

  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    document.querySelectorAll('[id^="video-wrapper-"]').forEach(wrapper => {
      const index = wrapper.getAttribute('data-index');
      const iframe = document.getElementById(`video-iframe-${index}`);
      const loadingIndicator = document.getElementById(`loading-indicator-${index}`);

      iframe.onload = function() {
        loadingIndicator.style.display = 'none';
        iframe.style.display = 'block';
      };
    });
  }
}