import ConfigRoutes from "../../config/Routes"
import SessionsNew from "../controllers/devise/SessionsNew";
import PasswordNew from "../controllers/devise/PasswordNew";
import PasswordEdit from "../controllers/devise/PasswordEdit";
import ConfirmationNew from "../controllers/devise/ConfirmationNew";
import ConfirmationEdit from "../controllers/devise/ConfirmationEdit";
import RegistrationsNew from "../controllers/devise/RegistrationsNew";
import RegistrationsEdit from "../controllers/devise/RegistrationsEdit";
import Location from "../controllers/checkout/Location";
import Sessions from "../controllers/checkout/Sessions";
import SessionDate from "../controllers/checkout/SessionDate";
import Options from "../controllers/checkout/Options";
import Infos from "../controllers/checkout/Infos";
import Payment from "../controllers/checkout/Payment";
import LocationDepartment from "../controllers/checkout/LocationDepartement";
import SessionRecap from "../controllers/checkout/SessionRecap";
import InitialAssessmentIndex from "../controllers/initial_assessment/InitialAssessmentIndex";
import InitialAssessmentInformations from "../controllers/initial_assessment/InitialAssessmentInformations";
import AdministrativeFilesIndex from "../controllers/AdministrativeFilesIndex";
import StudentsDashboardV2Elements from "../controllers/StudentsDashboardV2Elements";
import StudentsTutosIndex from "../controllers/StudentsTutosIndex";

export default class Routes extends ConfigRoutes{

    static routes = {
        // Devise
        "CustomersSessionsNew": SessionsNew,
        "CustomersSessionsCreate": SessionsNew,
        "DevisePasswordsNew": PasswordNew,
        "DevisePasswordsCreate": PasswordNew,
        "DevisePasswordsEdit": PasswordEdit,
        "DevisePasswordsUpdate": PasswordEdit,
        "CustomersConfirmationsNew": ConfirmationNew,
        "CustomersConfirmationsCreate": ConfirmationNew,
        "CustomersConfirmationsShow": ConfirmationEdit,
        "CustomersConfirmationsUpdate": ConfirmationEdit,
        "CustomersRegistrationsNew": RegistrationsNew,
        "CustomersRegistrationsCreate": RegistrationsNew,
        "CustomersRegistrationsEdit": RegistrationsEdit,
        "CustomersRegistrationsUpdate": RegistrationsEdit,

        // Checkout
        "StudentsRegistrationOrdersLocationRegion": Location,
        "StudentsCheckoutLocationRegion": Location,
        "StudentsRegistrationOrdersLocationDepartment": LocationDepartment,
        "StudentsCheckoutLocationDepartment": LocationDepartment,
        "StudentsRegistrationOrdersSessions": Sessions,
        "StudentsCheckoutSessions": Sessions,
        "StudentsRegistrationOrdersSessionDate": SessionDate,
        "StudentsCheckoutSessionDate": SessionDate,
        "StudentsRegistrationOrdersSessionRecap": SessionRecap,
        "StudentsCheckoutSessionRecap": SessionRecap,
        "StudentsRegistrationOrdersOptions": Options,
        "StudentsCheckoutOptions": Options,
        "StudentsRegistrationOrdersInfos": Infos,
        "StudentsCheckoutInfos": Infos,
        "StudentsRegistrationOrdersPayment": Payment,
        "StudentsCheckoutPayment": Payment,

        // Initial Assessment
        "StudentsInitialAssessmentIndex": InitialAssessmentIndex,
        "StudentsInitialAssessmentQuestion": InitialAssessmentIndex,
        "StudentsInitialAssessmentInformations": InitialAssessmentInformations,

        // Administrative Files
        "StudentsAdministrativeFilesIndex": AdministrativeFilesIndex,

        // Dashboard
        "StudentsDashboardV2Elements": StudentsDashboardV2Elements,

        // Tutos
        "StudentsTutosIndex": StudentsTutosIndex
    };

    constructor() {
        super();
        this.routes = Object.assign(this.routes, Routes.routes);
    }
}